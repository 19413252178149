import { IUser_Profile_Form, IUser, IContact_Address } from '@web/types/user';
import { supabaseClient } from '@web/utils/supabase-client';
import { EUSER_ROLE } from '../store/auth-store';

class UserLogic {
  public async getUserProfileInfo() {
    const { data, error } = await supabaseClient.from('profile').select('*');
    if (error) {
      console.error('error', error);
      throw error;
    }
    if (!data) {
      return null;
    }
    return data;
  }

  public async updateUserProfileInfo(
    userId: string,
    payload: IUser_Profile_Form,
  ) {
    const { status, error } = await supabaseClient
      .from('profile')
      .update(payload.contact_info as IUser)
      .eq('id', userId);
    if (error) {
      console.error('error', error);
      throw error;
    }
    return status;
  }

  public async upsertContactAddress(payload: IUser_Profile_Form) {
    const { status, error } = await supabaseClient
      .from('profile_address')
      .upsert(payload.contact_address as IContact_Address);
    if (error) {
      console.error('error', error);
      throw error;
    }
    return status;
  }

  public async updateUserProfileAndContactAddress({
    userId,
    payload,
  }: {
    userId: string;
    payload: IUser_Profile_Form;
  }) {
    const user_profile = await this.updateUserProfileInfo(userId, payload);
    const user_address = await this.upsertContactAddress(payload);

    if (user_address && user_profile) {
      return 200;
    } else {
      throw new Error('Failed to update user profile and contact address');
    }
  }

  public async getProfileAddress(userId: string) {
    const { data, error } = await supabaseClient
      .from('profile_address')
      .select('*')
      .eq('id', userId);
    if (error) {
      console.error('error', error);
      throw error;
    }
    return data;
  }

  public getFormData(initialValue: IUser | null) {
    return {
      contact_info: {
        id: initialValue?.id || '',
        email: initialValue?.email || '',
        full_name: initialValue?.full_name || '',
        phone: initialValue?.phone || '',
        contact_address: initialValue?.contact_address || '',
        picture: '',
      },
      contact_address: initialValue?.address || undefined,
    };
  }

  public async retrieveSession() {
    const { data, error } = await supabaseClient.auth.getSession();

    if (error || !data.session) {
      console.error('error', error);
      throw error;
    }

    const user_address = await this.getProfileAddress(data.session.user.id);
    const user_profile = await this.getUserProfileInfo();
    const user_detail = user_profile?.[0];
    const address = user_address?.[0];
    const role: EUSER_ROLE =
      data.session.user?.app_metadata?.role ?? EUSER_ROLE.USER;
    const phone = user_detail?.phone ?? data.session.user.phone ?? '';
    const email = user_detail?.email ?? data.session.user.email ?? '';
    const full_name: string =
      user_detail?.full_name ?? data.session.user.user_metadata.name ?? '';
    const contact_address: string = user_detail?.contact_address ?? '';
    const picture: string = data.session.user.user_metadata.avatar_url ?? '';

    return {
      id: data.session.user.id,
      email,
      full_name,
      phone,
      contact_address,
      picture,
      address,
      role,
    };
  }
}

export const userLogic = new UserLogic();
