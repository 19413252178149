import { BUCKET_URL } from '@web/constants/bucket';
import { MAX_GET_BU_LIMIT } from '@web/constants/general';
import {
  EImageStatus,
  IBusinessApi,
  IBusinessForm,
  IBusiness_Address,
  IBusiness_Info,
} from '@web/types/business';
import { IBaseFilterParams } from '@web/types/queryParams';
import { supabaseClient } from '@web/utils/supabase-client';

class BusinessLogic {
  public async createBusiness(userId: string) {
    const { data, error } = await supabaseClient
      .from('business_info')
      .insert({
        business_name: '',
        host_id: userId,
      })
      .select(`*`)
      .single();
    if (error) {
      console.error('error', error);
      throw error;
    }

    return data;
  }

  public async upsertBusinessesPawsomeById(payload: {
    id: string;
    cleanliness: number;
    comfort: number;
    expertise: number;
    luxury: number;
    value: number;
  }) {
    const { data, error } = await supabaseClient
      .from('business_pawsome')
      .upsert(payload);

    if (error) {
      console.error('error', error);
      throw error;
    }
    return data;
  }

  public async createBusinessIfNoActiveOrNoExist(userId: string) {
    const createBusiness = await this.createBusiness(userId);

    if (!createBusiness.id) {
      throw createBusiness;
    }

    const prepare_data = {
      id: createBusiness.id,
      cleanliness: 0,
      comfort: 0,
      expertise: 0,
      luxury: 0,
      value: 0,
    };

    const upsertBusinessPawsome =
      await this.upsertBusinessesPawsomeById(prepare_data);

    const data = { ...createBusiness, rating: upsertBusinessPawsome };

    if (!data) {
      console.error('error');
      throw 'error';
    }

    return data;
  }

  public async getMyBusinessList(
    userId: string,
    limit: number = MAX_GET_BU_LIMIT,
  ) {
    const { data, error } = await supabaseClient
      .from('business_info')
      .select(
        `id, business_name, description, created_at, updated_at, host_id, is_done, verify_status,
    contact_address (
      *
    ),
    contact_info(
      *
    ),
    business_address(
      *
    ),
    business_image(
      *
    )
    `,
      )
      .eq('host_id', userId)
      .limit(limit);

    if (error) {
      console.error('error', error);
      throw error;
    }

    if (!data) {
      return null;
    }
    return data;
  }

  public async getActiveBusinessInfo(userId: string) {
    const { data, error } = await supabaseClient
      .from('business_info')
      .select(
        `id, business_name, description, created_at, updated_at, host_id, is_done, verify_status,
      contact_address (
        *
      ),
      contact_info(
        *
      ),
      business_address(
        *
      ),
      business_image(
        *
      )
      `,
      )
      .eq('host_id', userId)
      .single();
    if (error) {
      console.error('error', error);
      throw error;
    }

    if (!data) {
      return null;
    }
    return data;
  }

  public async getBusinessById(id: string, detail?: boolean) {
    const { data, error } = await supabaseClient
      .from('business_info')
      .select(
        `
        id,
        host_id,
        business_name,
        description,
        is_done,
        verify_status,
        metadata,
        business_image ( url ),
        business_address (*),
        business_service (*),
        contact_info(*),
        contact_address(*),
        business_pawsome(*)
        `,
      )
      .match({ id })
      .limit(1)
      .single();

    if (error) {
      console.error('error', error);
      throw error;
    }

    if (detail) {
      if (data && data.is_done && data.verify_status === 'verified') {
        return data;
      } else {
        throw new Error('Business not found or not verified');
      }
    }

    return data;
  }

  public async getBusinessesInfo(limit: number = MAX_GET_BU_LIMIT) {
    const { data, error } = await supabaseClient
      .from('business_info')
      .select(
        `
        id,
        host_id,
        business_name,
        description,
        is_done,
        verify_status,
        business_image ( url ),
        business_address (*),
        contact_info(email,full_name,created_at)
        `,
      )
      .eq('verify_status', 'verified')
      .is('is_done', true)
      .is('is_active', true)
      .limit(limit);

    if (error) {
      console.error('error', error);
      throw error;
    }
    return data;
  }

  public async getAllBusinesses(
    params: IBaseFilterParams & { verify_status?: string },
  ) {
    let query = supabaseClient
      .from('business_info')
      .select(
        `
      id,
      host_id,
      business_name,
      description,
      is_done,
      verify_status,
      business_image ( url ),
      business_address (*),
      contact_info(email,full_name,created_at)
      `,
        { count: 'exact' },
      )
      .range(params.from, params.to);
    if (params.search) {
      query = query.ilike('business_name', `%${params.search}%`);
    }

    if (params.verify_status === 'verified') {
      query = query.or(`verify_status.eq.verified`);
    }

    if (params.verify_status === 'Not verify') {
      query = query.or(`verify_status.eq.Not verify,verify_status.is.null`);
    }

    if (params.verify_status === 'all') {
      query = query.or(
        `verify_status.eq.verified,verify_status.eq.Not verify,verify_status.is.null`,
      );
    }

    if (params.sort) {
      query = query.order(params.sort, {
        ascending: params.order === 'desc',
        nullsFirst: true,
      });
    }

    const { data, error, count } = await query;

    if (error) {
      console.error('error', error);
      throw error;
    }
    return {
      list: data,
      count,
    };
  }

  public async approveBusiness(id: string, status: 'verified' | null) {
    const { data, error } = await supabaseClient
      .from('business_info')
      .update({ verify_status: status })
      .eq('id', id);

    if (error) {
      console.error('error', error);
      throw error;
    }
    return data;
  }

  public async getBusinessesPawsomeById(id: string) {
    const { data, error } = await supabaseClient
      .from('business_pawsome')
      .select(`*`)
      .eq('id', id)
      .single();

    if (error) {
      console.error('error', error);
      throw error;
    }
    return data;
  }

  public async initUpdateBusinessInfo(
    business_id: string,
    host_id: string,
    payload: IBusinessForm,
    is_done: boolean,
  ) {
    const location = payload.business_address.location
      ? `POINT(${payload.business_address.location?.coordinates[0]} ${payload.business_address.location?.coordinates[1]})`
      : null;
    const { data, error } = await supabaseClient.rpc('update_business_fn', {
      business_id: business_id,
      host_id: host_id,
      contact_params: {
        email: payload.contact_info.email,
        full_name: payload.contact_info.full_name,
        phone: payload.contact_info.phone,
      },
      contact_address_params: {
        sub_district: payload.contact_address.sub_district,
        district: payload.contact_address.district,
        province: payload.contact_address.province,
        postal_code: payload.contact_address.postal_code,
        country: payload.contact_address.country,
        address: payload.contact_address.address || null,
      },
      business_info_params: {
        business_name: payload.business_info.name,
        description: payload.business_info.description,
        is_done: is_done,
        verify_status: payload.business_info.verify_status || null,
        metadata: payload.business_info.metadata || null,
      },
      business_address_params: {
        country: payload.business_address.country ?? 'Thailand',
        postal_code: payload.business_address.postal_code ?? '',
        sub_district: payload.business_address.sub_district ?? '',
        province: payload.business_address.province ?? '',
        district: payload.business_address.district ?? '',
        address: payload.business_address.address || null,
        is_contact_address: payload.business_address.is_contact_address,
        map_url: payload.business_address.map_url || null,
        location: location,
      },
    });
    if (error) {
      console.error('error', error);
      throw error;
    }
    return data;
  }

  public async updateBusinessAddress(
    id: string,
    userId: string,
    payload: IBusiness_Address,
  ) {
    const { data, error } = await supabaseClient
      .from('business_address')
      .update({
        country: payload.country,
        postal_code: payload.postal_code,
        sub_district: payload.sub_district,
        province: payload.province,
        district: payload.district,
        address: payload.address,
        map_url: payload.map_url,
        location: `POINT(${payload.location?.coordinates[0]} ${payload.location?.coordinates[1]})`,
      })
      .eq('host_id', userId)
      .eq('id', id);
    if (error) {
      console.error('error', error);
      throw error;
    }
    return data;
  }

  public async updateBusinessInfo(
    id: string,
    userId: string,
    payload: IBusiness_Info,
  ) {
    const { data, error } = await supabaseClient
      .from('business_info')
      .update({
        business_name: payload.name,
        description: payload.description,
        metadata: payload.metadata,
      })
      .eq('host_id', userId)
      .eq('id', id);
    if (error) {
      console.error('error', error);
      throw error;
    }
    return data;
  }

  public getImageUrl(rawUrl: string) {
    return `${BUCKET_URL}/${rawUrl}`;
  }

  public getFormData(initialValuse: IBusinessApi | null) {
    return {
      id: initialValuse?.id || '',
      contact_info: {
        email: initialValuse?.contact_info?.email || '',
        full_name: initialValuse?.contact_info?.full_name || '',
        phone: initialValuse?.contact_info?.phone || '',
      },
      contact_address: {
        address: initialValuse?.contact_address?.address || '',
        country: initialValuse?.contact_address?.country || 'Thailand',
        created_at: initialValuse?.contact_address?.created_at || '',
        district: initialValuse?.contact_address?.district || '',
        host_id: initialValuse?.contact_address?.host_id || '',
        id: initialValuse?.contact_address?.id || '',
        postal_code: initialValuse?.contact_address?.postal_code || '',
        province: initialValuse?.contact_address?.province || '',
        sub_district: initialValuse?.contact_address?.sub_district || '',
        updated_at: initialValuse?.contact_address?.updated_at || '',
      },
      business_address: {
        isContactAddress: false,
        country: initialValuse?.business_address?.country || 'Thailand',
        postal_code: initialValuse?.business_address?.postal_code || '',
        sub_district: initialValuse?.business_address?.sub_district || '',
        province: initialValuse?.business_address?.province || '',
        district: initialValuse?.business_address?.district || '',
        is_contact_address:
          initialValuse?.business_address?.is_contact_address || false,
        address: initialValuse?.business_address?.address || '',
        map_url: initialValuse?.business_address?.map_url || '',
        location: initialValuse?.business_address?.location || null,
      },
      business_info: {
        name: initialValuse?.business_name || '',
        description: initialValuse?.description || '',
        metadata:
          (initialValuse?.metadata as {
            [key: string]: string | undefined;
          }) || null,
      },
      business_image: initialValuse?.business_image.map((img) => {
        return {
          trackId: img.url,
          url: img.url ? businessLogic.getImageUrl(img.url) : '',
          name: img.url ? img.url : '',
          status: EImageStatus.DONE,
        };
      }),
    };
  }
}

export const businessLogic = new BusinessLogic();

export const calculateRating = (
  expertise: number,
  comfort: number,
  value: number,
  luxury: number,
  cleanliness: number,
): number => {
  // Define the weights
  const weights = {
    expertise: 5,
    comfort: 5,
    value: 5,
    luxury: 5,
    cleanliness: 5,
  };

  // Calculate the weighted scores
  const weightedExpertise = expertise * weights.expertise;
  const weightedComfort = comfort * weights.comfort;
  const weightedValue = value * weights.value;
  const weightedLuxury = luxury * weights.luxury;
  const weightedCleanliness = cleanliness * weights.cleanliness;

  // Sum the weighted scores
  const totalWeightedScore =
    weightedExpertise +
    weightedComfort +
    weightedValue +
    weightedLuxury +
    weightedCleanliness;

  // Calculate the maximum possible score
  const maxScore =
    5 *
    (weights.expertise +
      weights.comfort +
      weights.value +
      weights.luxury +
      weights.cleanliness);

  // Normalize the score to a scale of 0 to 5
  const normalizedScore = (totalWeightedScore / maxScore) * 5;

  // Round to one decimal place, rounding .35 and above up and .34 and below down
  const roundedScore = Math.round(normalizedScore * 10) / 10;

  return roundedScore;
};
