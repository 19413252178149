import { Json } from '@repo/types';

export type IBusinessForm = {
  id?: string;
  contact_info: IContact_Info_Form;
  contact_address: IContact_Address;
  business_info: IBusiness_Info;
  business_address: IBusiness_Address & {
    is_contact_address: boolean;
  };
  business_image: IImage[];
};

export type IContact_Info_Form = {
  email: string;
  full_name: string;
  phone: string;
};

export type IContact_Info = {
  email: string | null;
  full_name: string;
  phone: string | null;
};

export type IContact_Address = {
  address: string;
  country: string;
  created_at: string;
  district: string;
  host_id: string;
  id: string;
  postal_code: string;
  province: string;
  sub_district: string;
  updated_at: string | null;
};

export const enum EImageStatus {
  LOADING = 'loading',
  DONE = 'done',
  ERROR = 'error',
}

export type IImage = {
  trackId: string;
  url: string;
  name: string;
  status: EImageStatus;
};

export type IBusiness_Info = {
  name: string;
  description: string;
  verify_status: string | null;
  metadata: {
    phone: string;
    bu_line_id: string;
    line_id: string;
    facebook: string;
  } | null;
};

export type IBusiness_Address = {
  country?: string;
  postal_code?: string;
  sub_district?: string;
  province?: string;
  district?: string;
  is_contact_address?: boolean;
  address: string;
  map_url: string;
  location?: {
    type: string;
    coordinates: [number, number];
  } | null;
};

export type IBusiness_Image = {
  business_id: string;
  created_at: string;
  host_id: string;
  id: string;
  updated_at: string | null;
  url: string;
};

export type IBusiness = {
  id: string;
  business_name: string;
  description: string | null;
  created_at: string;
  updated_at: string | null;
  host_id: string;
  is_done?: boolean;
  verify_status?: string | null;
  contact_address: IContact_Address | null;
  contact_info: IContact_Info | null;
  business_address: IBusiness_Address | null;
  business_image: IBusiness_Image[];
  metadata: Json | null;
};

export type IBusiness_AddressApi = {
  country?: string;
  postal_code?: string;
  sub_district?: string;
  province?: string;
  district?: string;
  is_contact_address?: boolean;
  address: string;
  map_url: string;
  location?: {
    type: string;
    coordinates: [number, number];
  } | null;
};

export type IBusinessApi = {
  id: string;
  business_name: string;
  description: string | null;
  created_at: string;
  updated_at: string | null;
  host_id: string;
  is_done?: boolean;
  verify_status?: string | null;
  contact_address: IContact_Address | null;
  contact_info: IContact_Info | null;
  business_address: IBusiness_AddressApi | null;
  business_image: IBusiness_Image[];
  metadata: Json | null;
};
