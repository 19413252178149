import { create } from 'zustand';
import { supabaseClient } from '@web/utils/supabase-client';
import { ROUTES } from '@web/constants/route';
import { userLogic } from '../user-logic';
import { IContact_Address } from '@web/types/user';

export const enum EUSER_ROLE {
  SUPER_ADMIN = 'super-admin',
  USER = 'user',
}
export type IAuthState = {
  user: {
    id: string;
    email: string;
    full_name?: string;
    phone?: string;
    contact_address?: string;
    picture?: string;
    address?: IContact_Address | null;
    role: EUSER_ROLE;
  } | null;
  isAdmin: boolean;
  isAuthenticating: boolean;
};

export type IAuthActions = {
  login: (redirectUrl?: string) => Promise<void>;
  logout: () => Promise<void>;
  retrieveSession: () => Promise<{ id: string; email: string } | null>;
};

export const useAuthStore = create<IAuthState & IAuthActions>()((set) => {
  return {
    user: null,
    isAuthenticating: true,
    isAdmin: false,
    login: async (redirectUrl?: string) => {
      await supabaseClient.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: redirectUrl
            ? `${location.origin}${redirectUrl}`
            : `${location.origin}/auth/callback`,
        },
      });
    },
    logout: async () => {
      await supabaseClient.auth.signOut();
      window.location.href = ROUTES.HOME;
    },
    retrieveSession: async () => {
      try {
        set({ isAuthenticating: true });
        const result = await userLogic.retrieveSession();

        set({
          user: result,
          isAdmin: result?.role === EUSER_ROLE.SUPER_ADMIN,
          isAuthenticating: false,
        });

        return (
          {
            id: result?.id || '',
            email: result?.email || '',
          } || null
        );
      } catch (error) {
        set({ isAuthenticating: false });
        console.error('Error retrieving session', error);
        throw error;
      }
    },
  };
});
