export const ROUTES = {
  HOME: '/',
  USER: '/user',
  // TODO: removed My Bookings route first, will implement later
  USER_BOOKINGS: '/user/bookings',
  USER_PROFILE: '/user/profile',
  USER_PET_DETAILS: '/user/pet-details',
  BUSINESS_CREATOR: '/business-creator',
  BUSINESS_CREATOR_GET_START: '/business-creator/get-start',
  BUSINESS_CREATOR_CREATE_NEW: '/business-creator/new',
  BUSINESSES: '/businesses',
  MY_BUSINESS: '/my-business',
  BUSINESS_DETAIL: '/business-detail',
  HOST_INFO: '/host-info',
  BUSINESS_INFO: '/business-info',
  ADMIN: '/admin',
};

export const USER_MENUS = [
  {
    label: 'Personal info',
    to: ROUTES.USER_PROFILE,
  },
];
export const FOOTER_MENUS = [
  {
    label: 'Privacy Policy',
    to: '/privacy-policy',
  },
  {
    label: 'Contact Us',
    to: '/contact-us',
  },
];
